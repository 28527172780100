@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Baby Doll';
  src: url('../assets/fonts/baby-doll/baby-doll.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
